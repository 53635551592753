var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6",attrs:{"fluid":""}},[_c('v-layout',{attrs:{"justify-space-between":""}},[_c('h1',{staticClass:"m-0"},[_vm._v("Categories")]),_c('type-create-modal',{on:{"updated":function($event){return _vm.getItems(_vm.query)}}})],1),_c('v-row',[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"ml-3",attrs:{"value":_vm.$route.query.category,"items":_vm.$types.types,"item-text":"name","item-value":"value","label":"타입","required":""},on:{"change":function($event){return _vm.$router.replace(Object.assign({}, _vm.$route,
            {query: Object.assign({}, _vm.$route.query, {category: $event})}))}}})],1)],1),_c('v-data-table',{ref:"sortableTable",staticClass:"mt-4",attrs:{"item-key":"value","headers":_vm.headers,"items":_vm.items,"server-items-length":10,"hide-default-footer":""},on:{"update:page":function($event){return _vm.getItems(Object.assign({}, _vm.query, {page: $event}))}},scopedSlots:_vm._u([{key:"header.handle",fn:function(ref){return [_vm._v(" 순서 "),_c('v-btn',{staticClass:"sort-handle",attrs:{"color":"primary","x-small":"","icon":""},on:{"click":_vm.saveSort}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}},{key:"item.handle",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"sort-handle",staticStyle:{"cursor":"move"},attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-drag-horizontal-variant")])],1)]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('type-create-modal',{attrs:{"update":"","item":item},on:{"updated":function($event){return _vm.getItems(Object.assign({}, _vm.query, {page: $event}))}}})]}},{key:"item.options",fn:function(ref){
          var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.patchItem(item, $event)}},model:{value:(item.disabled),callback:function ($$v) {_vm.$set(item, "disabled", $$v)},expression:"item.disabled"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }