export default {
  // client측 자료
  types: [
    {
      name: "공지사항",
      value: "notice",
    },
    {
      name: "문의사항",
      value: "inquiry",
    },
    {
      name: "약관",
      value: "terms",
    },
    {
      name: "보험",
      value: "insurance",
    },
    {
      name: "GA",
      value: "company-ga",
    },
    {
      name: "손해보험",
      value: "company-sonhae",
    },
    {
      name: "생명보험",
      value: "company-life",
    },
    {
      name: "지역",
      value: "area",
    },
  ],
  insType: [
    {
      name: "종합보험",
      value: "ga",
    },
    {
      name: "생명보험",
      value: "life",
    },
    {
      name: "손해보험",
      value: "sonhae",
    },
  ],
  size: [
    {
      name: "10인 미만",
      value: "1",
    },
    {
      name: "10인 이상",
      value: "2",
    },
    {
      name: "20인 이상",
      value: "3",
    },
    {
      name: "30인 이상",
      value: "4",
    },
    {
      name: "40인 이상",
      value: "5",
    },
    {
      name: "50인 이상",
      value: "6",
    },
    {
      name: "100인 이상",
      value: "7",
    },
  ],
  gender: [
    {
      name: "남성",
      value: "male",
    },
    {
      name: "여성",
      value: "female",
    },
  ],
  age: [
    {
      name: "20대",
      value: 2,
    },
    {
      name: "30대",
      value: 3,
    },
    {
      name: "40대",
      value: 4,
    },
    {
      name: "50대",
      value: 5,
    },
  ],
  career: [
    {
      name: "1년 이상",
      value: 1,
    },
    {
      name: "3년 이상",
      value: 2,
    },
    {
      name: "5년 이상",
      value: 3,
    },
    {
      name: "10년 이상",
      value: 4,
    },
  ],
  period: [
    {
      name: "1년",
      value: 1,
    },
    {
      name: "2년",
      value: 2,
    },
    {
      name: "3년",
      value: 3,
    },
    {
      name: "4년",
      value: 4,
    },
    {
      name: "5년",
      value: 5,
    },
    {
      name: "6년",
      value: 6,
    },
    {
      name: "7년",
      value: 7,
    },
    {
      name: "8년",
      value: 8,
    },
    {
      name: "9년",
      value: 9,
    },
    {
      name: "10년",
      value: 10,
    },
    {
      name: "10년 이상",
      value: 11,
    },
  ],
  // server측 자료
  area: [],
  insurance: [],
  ga: [],
  life: [],
  sonhae: [],
};
