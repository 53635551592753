<template>
  <v-dialog persistent max-width="600px" v-model="dialog" @input="init">
    <template #activator="{ on, attrs }">
      <a
        text
        class="secondary--text"
        :style="{ textDecoration: 'underline' }"
        small
        v-if="update"
        v-on="on"
        >{{ item.name || "(제목 없음)" }}</a
      >
      <v-btn fab small v-else v-on="on">
        <v-icon v-bind="attrs"> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">타입 생성</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="d-flex align-items-center">
              <v-select
                :disabled="!!input.value"
                v-model="input.category"
                :items="$types.types"
                item-text="name"
                item-value="value"
                label="타입"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex align-items-center">
              <v-text-field
                v-model="input.name"
                label="이름"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="cancel">취소</v-btn>
        <v-btn color="blue darken-1" text @click="submit">{{
          item === null ? "등록" : "수정"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateModal",
  props: {
    update: {
      type: Boolean,
    },
    item: {
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      dialog: false,
      datePickerOn: false,
      input: {
        value: null,
        type: "insurance",
        name: "",
      },
    };
  },
  methods: {
    cancel() {
      this.input = {
        value: null,
        type: "insurance",
        name: "",
      };
      this.dialog = false;
    },
    init(bool) {
      if (bool && this.item) {
        this.input = JSON.parse(JSON.stringify(this.item));
      }
    },
    async submit() {
      try {
        const { data } = await this.$axios[this.input.value ? "patch" : "post"](
          "/types",
          this.input
        );
        this.$emit("updated");
        this.cancel();
      } catch (error) {}
    },
  },
};
</script>

<style></style>
