<template>
  <v-container class="px-6" fluid>
    <v-layout justify-space-between>
      <h1 class="m-0">Payment Amounts</h1>
      <amount-create-modal @updated="getItems()" />
    </v-layout>
    <v-data-table
      item-key="value"
      :headers="headers"
      :items="items"
      :server-items-length="10"
      class="mt-4"
      @update:page="getItems({ ...query, page: $event })"
      hide-default-footer
      ref="sortableTable"
    >
      <template #item.price="{ item }">
        <amount-create-modal
          @updated="getItems({ ...query, page: $event })"
          update
          :item="item"
        />₩
      </template>
      <template #item.per="{ item }"> {{ item.per }}% </template>
      <template #item.options="{ item }">
        <v-btn x-small fab color="error">
          <v-icon> mdi-trash-can </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      circle
      class="mt-6"
      v-model="$route.query.page"
      :length="pagination.totalPages"
      @input="getItems"
    />
  </v-container>
</template>

<script>
import AmountCreateModal from "../../components/AmountCreateModal.vue";

export default {
  components: { AmountCreateModal },
  data() {
    return {
      items: [],
      selected: [],
      pagination: {},
      input: {},
      headers: [
        { sortable: false, text: "id", align: "start", value: "id" },
        {
          sortable: false,
          text: "금액",
          value: "price",
        },
        {
          sortable: false,
          text: "추가적립",
          value: "per",
        },
        { sortable: false, text: "생성일", value: "createdAt" },
        { sortable: false, text: "제거", value: "options" },
      ],
    };
  },
  computed: {
    query() {
      return { ...this.$route.query };
    },
  },
  watch: {
    query(n) {
      this.getItems(n);
    },
  },
  methods: {
    async getItems(params) {
      try {
        const { data } = await this.$axios.get(`/payments/prices`, {
          params: params || this.$route.query,
        });
        this.items = data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async patchItem({ value, disabled }) {
      this.$axios.patch("/payments/prices", { value, disabled });
    },
  },
  async mounted() {
    this.getItems(this.query);
  },
};
</script>
