<template>
  <v-container class="px-6" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <h1>
          {{ meta.edit ? "Edit" : "Write" }}
        </h1>
        <v-card class="pa-6">
          <v-form @submit.prevent.stop="submit" v-model="valid">
            <v-row>
              <v-col cols="4">
                <v-select
                  label="Category"
                  v-model="input.category"
                  item-text="name"
                  item-value="value"
                  :items="categories"
                  hide-details
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="Title"
                  v-model="input.title"
                  hide-details
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <!-- <vue-editor
                  v-model="input.content"
                  useCustomImageHandler
                  @image-added="imageAdded"
                  @image-removed="imageRemoved"
                /> -->

                <v-textarea
                  cols="30"
                  rows="10"
                  v-model="input.content"
                  outlined
                />
              </v-col>
            </v-row>
            <v-layout justify-end class="mt-6">
              <v-btn color="primary" outlined class="mr-2" @click="deleteItem">
                delete
              </v-btn>
              <v-btn color="primary" type="submit">
                {{ meta.edit ? "update" : "Write" }}
              </v-btn>
              <!-- <v-btn color="primary" @click="getImagesSrc">
                {{ meta.edit ? "update" : "Write" }}
              </v-btn> -->
            </v-layout>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
      loading: false,
      categories: [],
      input: {
        category: null,
        client: null,
        title: null,
        members: [],
        content: null,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      valid: false,
    };
  },
  methods: {
    uploadFile(file, keyname) {
      this[keyname].file = file;
      this[keyname].src = URL.createObjectURL(file);
    },
    async submit() {
      try {
        const body = {
          ...this.input,
          content: this.input.content.split("\n").join("<br/>"),
        };
        const { data } = await this.$axios[this.meta.edit ? "patch" : "post"](
          "/terms",
          body
        );
        if (data.success) {
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getItem() {
      const { id } = this.$route.params;

      try {
        const { data } = await this.$axios.get(`/terms/${id}`);
        this.input = {
          ...data.data,
          content: data.data.content.split("<br/>").join("\n"),
        };
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async getCategory() {
      const { data } = await this.$axios.get("/types", {
        params: { category: "terms" },
      });
      this.categories = data.data;
    },
    async deleteItem() {
      try {
        const bool = confirm("정말 삭제할까요?");
        if (!bool) return;
        const { data } = await this.$axios.delete(
          `/terms/${this.$route.params.id}`
        );
        if (data.success) {
          this.$router.go(-1);
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getCategory();
    const { id } = this.$route.params;
    if (id !== undefined) {
      this.getItem();
    }
  },
};
</script>
