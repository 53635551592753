<template>
  <v-container class="px-6" fluid>
    <v-layout justify-space-between>
      <h1 class="m-0">Categories</h1>
      <type-create-modal @updated="getItems(query)" />
    </v-layout>
    <v-row>
      <v-col cols="12" md="3" class="ml-auto">
        <v-select
          class="ml-3"
          @change="
            $router.replace({
              ...$route,
              query: { ...$route.query, category: $event },
            })
          "
          :value="$route.query.category"
          :items="$types.types"
          item-text="name"
          item-value="value"
          label="타입"
          required
        />
      </v-col>
    </v-row>
    <v-data-table
      item-key="value"
      :headers="headers"
      :items="items"
      :server-items-length="10"
      class="mt-4"
      @update:page="getItems({ ...query, page: $event })"
      hide-default-footer
      ref="sortableTable"
    >
      <template #header.handle="{}">
        순서
        <v-btn
          color="primary"
          x-small
          icon
          class="sort-handle"
          @click="saveSort"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </template>
      <template #item.handle="{ item }">
        <v-btn style="cursor: move" icon class="sort-handle">
          <v-icon>mdi-drag-horizontal-variant</v-icon>
        </v-btn>
      </template>
      <template #item.name="{ item }">
        <type-create-modal
          @updated="getItems({ ...query, page: $event })"
          update
          :item="item"
        />
      </template>
      <template #item.options="{ item }">
        <v-switch
          class="ma-0"
          v-model="item.disabled"
          hide-details
          @change="patchItem(item, $event)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import TypeCreateModal from "../../components/TypeCreateModal.vue";
import Sortable from "sortablejs";

export default {
  components: { TypeCreateModal },
  data() {
    return {
      items: [],
      selected: [],
      input: {},
      headers: [
        { sortable: false, text: "순서", align: "start", value: "handle" },
        { sortable: false, text: "값", align: "start", value: "value" },
        {
          sortable: false,
          text: "타입",
          value: "category",
        },
        {
          sortable: false,
          text: "이름",
          value: "name",
        },
        { sortable: false, text: "생성일", value: "createdAt" },
        { sortable: false, text: "숨김", value: "options" },
      ],
    };
  },
  computed: {
    query() {
      return { ...this.$route.query };
    },
  },
  watch: {
    query(n) {
      this.getItems(n);
    },
  },
  methods: {
    async getItems(params) {
      try {
        const { data } = await this.$axios.get(`/types/manage`, {
          params: params || this.$route.query,
        });
        console.log("@@", data);
        this.items = data.data.map((item) => ({
          ...item,
          createdAt: item.createdAt
            .substr(0, 19)
            .split("T")
            .join(" "),
        }));

        // 카테고리는 소트 이슈로 페이지네이션이 없음
        // this.pagination = {
        //   page,
        //   totalPages,
        // };
      } catch (err) {
        console.log(err);
      }
    },
    async patchItem({ value, disabled }) {
      this.$axios.patch("/types", { value, disabled });
    },
    async saveSort() {
      try {
        await this.$axios.patch("/types/sort", {
          items: this.items.map((item) => ({
            value: item.value,
            sort: item.sort,
          })),
        });
        alert("순서가 저장되었습니다.");
      } catch (error) {}
    },

    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.items.splice(oldIndex, 1)[0];
      this.items.splice(newIndex, 0, movedItem);
      this.items = this.items.map((item, sort) => ({ ...item, sort }));
    },
  },
  async mounted() {
    if (!this.query.category) {
      this.$router.replace({
        ...this.$route.path,
        query: { category: this.$types.types[0].value },
      });
    } else this.getItems(this.query);
    // sortable
    new Sortable(
      this.$refs.sortableTable.$el.getElementsByTagName("tbody")[0],
      {
        draggable: "tr",
        handle: ".sort-handle",
        onEnd: this.dragReorder,
      }
    );
  },
};
</script>
