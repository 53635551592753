<template>
  <v-container class="px-6" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <h1>
          {{ meta.edit ? "Edit" : "Write" }}
        </h1>
        <v-card class="pa-6">
          <v-form @submit.prevent.stop="submit" v-model="valid">
            <v-row>
              <v-col cols="5">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="input.range"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="input.range && input.range.join(' ~ ')"
                      label="Range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="input.range" range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(input.range)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  label="Title"
                  v-model="input.title"
                  hide-details
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="4">
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an avatar"
                  prepend-icon="mdi-camera"
                  label="Avatar"
                  v-model="input.images"
                  @change="enrollThumbnail"
                ></v-file-input>
                <v-img
                  :lazy-src="thumbnail"
                  :src="thumbnail"
                  aspect-ratio="1"
                />
              </v-col>
              <v-col cols="8">
                <v-textarea
                  cols="30"
                  rows="10"
                  v-model="input.content"
                  outlined
                />
              </v-col>
            </v-row>
            <v-layout justify-end class="mt-6">
              <v-btn
                color="primary"
                outlined
                class="mr-2"
                @click="deleteItem"
                v-if="meta.edit"
              >
                delete
              </v-btn>
              <v-btn
                color="primary"
                outlined
                class="mr-2"
                @click="$router.go(-1)"
                v-else
              >
                cancel
              </v-btn>
              <v-btn color="primary" type="submit" :loading="loading">
                {{ meta.edit ? "UPDATE" : "WRITE" }}
              </v-btn>
              <!-- <v-btn color="primary" @click="getImagesSrc">
                {{ meta.edit ? "update" : "Write" }}
              </v-btn> -->
            </v-layout>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
      loading: false,
      categories: [],
      thumbnail: null,
      input: {
        // category: null,
        client: null,
        title: null,
        members: [],
        content: null,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      valid: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const body = {
          ...this.input,
          content: this.input.content.split("\n").join("<br/>"),
        };
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
          if (Array.isArray(body[key])) {
            body[key].forEach((item) => {
              formData.append(key, item);
            });
          } else {
            formData.append(key, body[key]);
          }
        });
        const { data } = await this.$axios[this.meta.edit ? "patch" : "post"](
          "/events",
          formData
        );
        if (data.success) {
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getItem() {
      const { id } = this.$route.params;

      try {
        const { data } = await this.$axios.get(`/events/${id}`);
        this.input = {
          ...data.data,
          content: data.data.content.split("<br/>").join("\n"),
        };
        this.thumbnail = this.s3 + data.data.origin;
      } catch (error) {
        console.log(error);
      }
    },
    // async getCategory() {
    //   const { data } = await this.$axios.get("/types", {
    //     params: { category: "notice" },
    //   });
    //   this.categories = data.data;
    // },
    async deleteItem() {
      try {
        const bool = confirm("정말 삭제할까요?");
        if (!bool) return;
        const { data } = await this.$axios.delete(
          `/events/${this.$route.params.id}`
        );
        if (data.success) {
          this.$router.go(-1);
        }
      } catch (error) {}
    },
    enrollThumbnail(file) {
      if (file) {
        const url = URL.createObjectURL(file);
        this.thumbnail = url;
      } else {
        this.thumbnail = null;
      }
    },
  },
  mounted() {
    // this.getCategory();
    const { id } = this.$route.params;
    if (id !== undefined) {
      this.getItem();
    }
  },
};
</script>
