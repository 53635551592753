<template>
  <v-container class="px-6" fluid>
    <v-layout justify-space-between>
      <h1>Events</h1>
      <v-btn fab small to="/events/create">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="10"
      class="mt-4"
      @update:page="getItems"
      hide-default-footer
    >
      <template #item.title="{ item }">
        <router-link class="secondary--text" :to="`/events/${item.id}`">{{
          item.title
        }}</router-link>
      </template>
      <template #item.category="{ item }">
        {{ (item.relativeCategory && item.relativeCategory[0].name) || "-" }}
      </template>
    </v-data-table>
    <v-pagination
      class="mt-6"
      v-model="pagination.page"
      :length="pagination.totalPages"
      @input="getItems"
    />
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      selected: [],
      pagination: {},
      headers: [
        { sortable: false, text: "No.", align: "start", value: "id" },
        // {
        //   sortable: false,
        //   text: "카테고리",
        //   align: "start",
        //   value: "category",
        // },
        { sortable: false, text: "제목", align: "start", value: "title" },
        { sortable: false, text: "작성일", value: "createdAt" },
      ],
    };
  },
  methods: {
    async getItems(n) {
      try {
        const params = { page: n };
        const { data } = await this.$axios.get(`/events`, { params });
        const { docs, page, totalPages } = data.data;
        this.items = docs.map((item) => ({
          ...item,
          createdAt: item.createdAt
            .substr(0, 19)
            .split("T")
            .join(" "),
        }));
        this.pagination = {
          page,
          totalPages,
        };
      } catch (err) {
        console.log(err);
      }
    },
  },
  async mounted() {
    await this.getItems();
  },
};
</script>

<style></style>
