<template>
  <v-app>
    <global-nav class="print-none" />
    <global-drawer class="print-none" />
    <v-main>
      <v-container fluid class="px-0">
        <v-breadcrumbs class="px-6 pb-0" :items="breadCrumbData" divider=">" />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import GlobalNav from "@/components/GlobalNav";
import GlobalDrawer from "@/components/GlobalDrawer";
export default {
  components: { GlobalNav, GlobalDrawer },
  computed: {
    breadCrumbData() {
      const effectiveItems = this.$route.matched.filter(({ name }) => !!name);
      return effectiveItems.map(({ name, path }, idx, arr) => {
        return {
          text: name,
          href: path,
          disabled: idx === arr.length - 1,
        };
      });
    },
  },
};
</script>

<style></style>
