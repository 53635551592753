<template>
  <v-container class="px-6" fluid>
    <h1>Dashboard</h1>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-6 d-flex flex-column fill-height">
          <h4>환경설정</h4>
          <v-switch
            label="설계사 추천 사용"
            v-model="input.useRecommend"
            @change="updateOptions"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      input: { useRecommend: false },
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    async getOptions() {
      const { data } = await this.$axios.get("/admin/options");
      this.input = data.data;
    },
    async updateOptions() {
      const { useRecommend } = this.input;
      await this.$axios.patch("/admin/options", {
        useRecommend,
      });
    },
  },
};
</script>
