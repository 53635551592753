<template>
  <v-container class="px-6" fluid>
    <v-layout justify-space-between>
      <h1>Points</h1>
    </v-layout>
    <v-data-table
      item-key="value"
      :headers="headers"
      :items="items"
      :server-items-length="10"
      class="mt-4"
      @update:page="getItems"
      hide-default-footer
    >
      <template #item.type="{ item }">
        {{ mapType(item) }}
      </template>
    </v-data-table>
    <v-pagination
      circle
      class="mt-6"
      v-model="pagination.page"
      :length="pagination.totalPages"
      @input="getItems"
    />
  </v-container>
</template>

<script>
import TypeCreateModal from "../../components/TypeCreateModal.vue";

export default {
  components: { TypeCreateModal },
  data() {
    return {
      items: [],
      selected: [],
      pagination: {},
      headers: [
        { sortable: false, text: "id", align: "start", value: "id" },
        {
          sortable: false,
          text: "타입",
          value: "type",
        },
        {
          sortable: false,
          text: "유저명",
          value: "relativeUser.name",
        },
        {
          sortable: false,
          text: "포인트명",
          value: "name",
        },
        { sortable: false, text: "생성일", value: "createdAt" },
      ],
    };
  },
  methods: {
    mapType(item) {
      // @ CHR 충전
      // @ USE 사용
      // @ DRW 인출
      // @ EXP 만료는 get할때 충전/적립 수정해서 보냄
      // @ RWD 적립
      const { type, expiry } = item;
      if (["RWD", "CHR"].includes(type) && expiry) item.type = "EXP";
      return {
        CHR: "충전",
        USE: "사용",
        DRW: "인출",
        EXP: "만료",
        RWD: "적립",
      }[type];
    },
    async getItems(n) {
      try {
        const params = { page: n };
        const { data } = await this.$axios.get(`/points`, { params });
        const { docs, page, totalPages } = data.data;
        this.items = docs.map((item) => ({
          ...item,
          createdAt: item.createdAt
            .substr(0, 19)
            .split("T")
            .join(" "),
        }));
        console.log(docs);
        this.pagination = {
          page,
          totalPages,
        };
      } catch (err) {
        console.log(err);
      }
    },
    async patchItem({ value, disabled }) {
      this.$axios.patch("/points", { value, disabled });
    },
  },
  async mounted() {
    await this.getItems();
  },
};
</script>
