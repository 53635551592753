<template>
  <v-container class="px-6" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <h1>
          {{ meta.edit ? "Edit" : "Write" }}
        </h1>
        <v-card class="pa-6">
          <v-form @submit.prevent.stop="submit" v-model="valid">
            <v-row>
              <v-col cols="3">
                <small>Thumbnail</small>
                <label class="d-block mb-6">
                  <v-file-input
                    @change="uploadFile($event, 'thumbnail')"
                    accept="image/*"
                    class="d-none"
                    v-model="thumbnail.file"
                    :rules="rules.required"
                  />
                  <v-img
                    ref="thumbnail"
                    min-height="20"
                    :aspect-ratio="thumbnail.src ? null : 1"
                    :src="thumbnail.src"
                    class="grey lighten-3"
                  ></v-img>
                </label>
                <small>Icon</small>
                <label class="d-block mb-6">
                  <v-file-input
                    @change="uploadFile($event, 'icon')"
                    accept="image/*"
                    class="d-none"
                    v-model="icon.file"
                    :rules="rules.required"
                  />
                  <v-img
                    min-height="20"
                    :aspect-ratio="1"
                    :src="icon.src"
                    class="grey lighten-3"
                  ></v-img>
                </label>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-col cols="4">
                    <v-select
                      label="Category"
                      v-model="input.category"
                      :items="categories"
                      hide-details
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Client"
                      v-model="input.client"
                      hide-details
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="input.done"
                          label="Done"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="input.done"
                        type="month"
                        no-title
                        scrollable
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Title"
                      v-model="input.title"
                      hide-details
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Link"
                      v-model="input.link"
                      hide-details
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- <vue-editor
                      v-model="input.content"
                      useCustomImageHandler
                      @image-added="imageAdded"
                      @image-removed="imageRemoved"
                    /> -->
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Members"
                      chips
                      small-chips
                      deletable-chips
                      :items="users"
                      item-text="name"
                      item-value="id"
                      v-model="input.members"
                      multiple
                      hide-details
                      :rules="rules.required"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-layout justify-end>
              <v-btn color="primary" outlined class="mr-2" @click="deleteItem">
                delete
              </v-btn>
              <v-btn color="primary" type="submit">
                {{ meta.edit ? "update" : "Write" }}
              </v-btn>
              <!-- <v-btn color="primary" @click="getImagesSrc">
                {{ meta.edit ? "update" : "Write" }}
              </v-btn> -->
            </v-layout>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { VueEditor } from "vue2-editor";
export default {
  // components: { VueEditor },
  data() {
    return {
      menu: false,
      loading: false,
      itemsToDelete: [],
      categories: [],
      users: [],
      thumbnail: {
        src: null,
        file: null,
      },
      icon: {
        src: null,
        file: null,
      },
      input: {
        category: null,
        client: null,
        title: null,
        members: [],
        content: null,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      valid: false,
    };
  },
  methods: {
    uploadFile(file, keyname) {
      this[keyname].file = file;
      this[keyname].src = URL.createObjectURL(file);
    },
    getIndex() {
      const indexList = this.contents.map((item) => item.i);
      for (var i = 0; i <= this.contents.length; i++) {
        if (!indexList.includes(i)) return i;
      }
    },
    delItem(i) {
      const item = this.contents.find((c) => c.i === i);
      if (toDeleteItem) {
        this.deletedItems.push({
          Key: !!item && item.srcTemp && item.srcTemp.split("/")[4],
        });
      }

      this.contents = this.contents.filter((item) => item.i !== i);
    },
    toggleBgSize(i) {
      this.contents = this.contents.map((content, idx) => {
        return idx === i ? { ...content, contain: !content.contain } : content;
      });
    },
    async submit() {
      const { clientWidth, clientHeight } = this.$refs.thumbnail.$el;
      const isEdit = this.$route.meta.edit;
      this.restoreImages();
      try {
        this.loading = true;
        const formData = new FormData();
        this.input.ratio = Math.round((clientWidth * 100) / clientHeight) / 100;

        Object.keys(this.input).forEach((key) => {
          if (Array.isArray(this.input[key])) {
            this.input[key].forEach((value) => {
              formData.append(key, value);
            });
          } else {
            formData.append(key, this.input[key]);
          }
        });

        if (isEdit) {
          // 수정인 경우 삭제할 리스트, id 추가

          if (this.thumbnail.file) {
            // thumbnail이 변경된 경우
            this.itemsToDelete.push(this.originalSrc.thumbnail);
            formData.append("thumbnail", this.thumbnail.file);
          }
          if (this.icon.file) {
            // icon이 변경된 경우
            this.itemsToDelete.push(this.originalSrc.icon);
            formData.append("icon", this.icon.file);
          }

          formData.append("itemsToDelete", this.itemsToDelete);

          const result = await this.$axios.patch("/items", formData);
          if (result.status === 200) {
            this.$router.go(-1);
          }
        } else {
          formData.append("thumbnail", this.thumbnail.file);
          formData.append("icon", this.icon.file);
          formData.append("itemsToDelete", this.itemsToDelete);

          const result = await this.$axios.post("/items", formData);
          if (result.status === 200) {
            this.$router.go(-1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getItem() {
      const params = this.$route.params;
      try {
        const { data } = await this.$axios.get("/items/detail", {
          params,
        });
        this.input = { ...data };
        this.thumbnail.src = this.s3 + data.thumbnail;
        this.icon.src = this.s3 + data.icon;
        this.originalSrc = {
          thumbnail: data.thumbnail,
          icon: data.icon,
        };
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    restoreImages() {
      const restore = (img) => {
        const key = img.src.substr(this.s3.length);
        this.itemsToDelete = this.itemsToDelete.filter((src) => src !== key);
      };

      const div = document.createElement("div");
      div.innerHTML = this.input.content;
      const imgs = Array.from(div.getElementsByTagName("img"));

      imgs.forEach(restore);
    },
    setYears() {
      const year = new Date().getFullYear();
      for (var y = 2018; y <= year; y++) {
        this.categories.push(y);
      }
    },
    async getUsers() {
      const { data } = await this.$axios.get("/account");
      this.users = data.data;
      console.log(this.users);
    },
    async deleteItem() {
      const { data } = await this.$axios.delete(
        `/items/${this.$route.params.id}`
      );
      if (data.success) {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    this.setYears();
    this.getUsers();
    const { id } = this.$route.params;
    if (id !== undefined) {
      this.getItem();
    }
  },
};
</script>
