<template>
  <v-layout fill-height align-center justify-center>
    <v-container>
      <v-row align-content="center" justify="center">
        <v-col cols="12" md="6">
          <v-card class="pa-12">
            <h3 class="text-left mb-6">Sign Up</h3>
            <v-form @submit.prevent.stop="submit" v-model="valid">
              <v-text-field
                :rules="rules.username"
                label="email"
                v-model="input.username"
              />
              <v-text-field
                :rules="rules.name"
                label="name"
                v-model="input.name"
              />
              <v-text-field
                :rules="rules.password"
                label="password"
                type="password"
                v-model="input.password"
              />
              <v-text-field
                :rules="rules.password2"
                label="password retype"
                type="password"
                v-model="input.password2"
              />
              <v-btn
                class="mx-2"
                type="submit"
                outlined
                color="primary"
                :disabled="!valid"
                >Sign Up</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    return {
      input: {
        username: null,
        password: null,
        name: null,
        userType: 1,
      },
      valid: false,
      rules: {
        username: [
          (value) => !!value || "Required.",
          (value) => (value || "").length >= 2 || "Min 2 characters",
          (value) => (value || "").length <= 36 || "Max 36 characters",
          (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
        ],
        name: [
          (value) => !!value || "Required.",
          (value) => (value || "").length >= 2 || "Min 2 characters",
          (value) => (value || "").length <= 6 || "Max 6 characters",
          (value) => {
            const pattern = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\*]+$/;
            return pattern.test(value) || "Invalid name.";
          },
        ],
        password: [
          (value) => !!value || "Required.",
          (value) => (value || "").length >= 8 || "Min 8 characters",
          (value) => (value || "").length <= 16 || "Max 16 characters",
          (value) => {
            const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
            return pattern.test(value) || "Invalid password.";
          },
        ],
        password2: [
          (value) => !!value || "Required.",
          (value) => (value || "").length >= 8 || "Min 8 characters",
          (value) => (value || "").length <= 16 || "Max 16 characters",
          (value) => {
            const bool = this.input.password === value;
            return bool || "Invalid password.";
          },
        ],
      },
    };
  },
  methods: {
    async submit() {
      try {
        const { data } = await this.$axios.post(
          "/account/register",
          this.input
        );
        if (data.success) {
          this.$router.push("/account/sign-in");
        } else {
          await alert(data.msg);
        }
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
  },
};
</script>

<style></style>
