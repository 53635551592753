<template>
  <v-layout fill-height align-center justify-center>
    <v-container>
      <v-row align-content="center" justify="center">
        <v-col cols="12" md="6">
          <v-card class="pa-12">
            <h3 class="text-left mb-6">Sign In</h3>
            <v-form @submit.prevent.stop="submit" v-model="valid">
              <v-text-field
                label="email"
                :rules="[(val) => !!val || '아이디를 입력해주세요.']"
                v-model="input.username"
              />
              <v-text-field
                type="password"
                label="password"
                :rules="[(val) => !!val || '비밀번호를 입력해주세요.']"
                v-model="input.password"
              />
              <div class="d-flex align-center mt-6">
                <small class="error--text">{{ err }}</small>
                <v-spacer></v-spacer>
                <v-btn to="/account/sign-up" outlined color="primary"
                  >Sign Up</v-btn
                >
                <v-btn
                  class="mx-2"
                  type="submit"
                  color="primary"
                  :disabled="!valid"
                  >Login</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    const isProduction = process.env.NODE_ENV === "production";
    return {
      input: {
        username: isProduction ? null : "whaledoll",
        password: isProduction ? null : "password1!",
      },
      valid: false,
      err: null,
    };
  },
  methods: {
    async submit() {
      try {
        const { data } = await this.$axios.post("/account/signin", this.input);
        if (data.success) {
          this.setUser(data);
          Notification.requestPermission().then(async (permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
              const messaging = firebase.messaging();

              try {
                const pushToken = await messaging.getToken();
                await this.$store.dispatch("setPushTOken", pushToken);
              } catch (error) {
                console.log(error);
              }
            } else {
              console.log("Unable to get permission to notify.");
            }
          });
        }
      } catch (error) {
        const { data } = error.response;
        this.err = data.msg;
      }
    },
    setUser({ token, user }) {
      localStorage.setItem("instalkAdmin__token", token);
      this.$store.commit("set", ["user", user]);
      this.$axios.defaults.headers.common = {
        Authorization: token,
      };
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
