<template>
  <div>
    <router-view v-if="loaded" />
    <v-overlay :value="!loaded" z-index="100000">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    loaded() {
      return this.$store.getters.loaded;
    },
  },
};
</script>
