import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Layout from "../views/Layout/index";
import Home from "../views/Home.vue";
import Account from "../views/Account/index";
import Users from "../views/Users/index";
import Types from "../views/Types/index";
import Notice from "../views/Notice/index";
import Events from "../views/Events/index";
import Terms from "../views/Terms/index";
import Inquiry from "../views/Inquiry/index";
import Points from "../views/Points/index";
import Prices from "../views/Prices/index";
import Spreadsheet from "../views/Spreadsheet/index";

Vue.use(VueRouter);

const RouterView = {
  render(h) {
    return h("router-view");
  },
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: Layout.Default,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: RouterView,
        children: [{ path: "/", name: "Home", component: Home }],
      },
      {
        path: "user",
        component: RouterView,
        children: [
          { path: "/", name: "UsersList", component: Users.Index },
          { path: "create", name: "UsersCreate", component: Users.Create },
          {
            path: ":id",
            name: "Users",
            component: Users.Create,
            meta: { edit: true },
          },
        ],
      },
      {
        path: "type",
        component: RouterView,
        children: [
          { path: "/", name: "TypesList", component: Types.Index },
          { path: "create", name: "TypesCreate", component: Types.Create },
          {
            path: ":id",
            name: "Types",
            component: Types.Create,
            meta: { edit: true },
          },
        ],
      },
      {
        path: "notice",
        component: RouterView,
        children: [
          { path: "/", name: "NoticesList", component: Notice.Index },
          {
            path: "create",
            name: "NoticesCreate",
            component: Notice.Create,
          },
          {
            path: ":id",
            name: "NoticesDetail",
            component: Notice.Create,
            meta: {
              edit: true,
            },
          },
        ],
      },
      {
        path: "events",
        component: RouterView,
        children: [
          { path: "/", name: "EventsList", component: Events.Index },
          {
            path: "create",
            name: "EventsCreate",
            component: Events.Create,
          },
          {
            path: ":id",
            name: "EventsDetail",
            component: Events.Create,
            meta: {
              edit: true,
            },
          },
        ],
      },
      {
        path: "inquiry",
        component: RouterView,
        children: [
          { path: "/", name: "InquiryList", component: Inquiry.Index },
          {
            path: "create",
            name: "InquiryCreate",
            component: Inquiry.Create,
          },
          {
            path: ":id",
            name: "InquiryDetail",
            component: Inquiry.Create,
            meta: {
              edit: true,
            },
          },
        ],
      },
      {
        path: "terms",
        component: RouterView,
        children: [
          { path: "/", name: "TermsList", component: Terms.Index },
          {
            path: "create",
            name: "TermsCreate",
            component: Terms.Create,
          },
          {
            path: ":id",
            name: "TermsDetail",
            component: Terms.Create,
            meta: {
              edit: true,
            },
          },
        ],
      },
      {
        path: "points",
        component: RouterView,
        children: [
          { path: "/", name: "PointsList", component: Points.Index },
          {
            path: "create",
            name: "PointsCreate",
            component: Points.Create,
          },
          {
            path: ":id",
            name: "PointsDetail",
            component: Points.Create,
            meta: {
              edit: true,
            },
          },
        ],
      },
      {
        path: "prices",
        component: RouterView,
        children: [
          { path: "/", name: "PricesList", component: Prices.Index },
          {
            path: "create",
            name: "PricesCreate",
            component: Prices.Create,
          },
          {
            path: ":id",
            name: "PricesDetail",
            component: Prices.Create,
            meta: {
              edit: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/account",
    redirect: "/account/login",
    component: Layout.Account,
    children: [
      {
        path: "login",
        name: "SignIn",
        component: Account.SignIn,
      },
      {
        path: "sign-up",
        name: "SignUp",
        component: Account.SignUp,
      },
      {
        path: "sign-out",
        name: "SignOut",
        component: Account.SignOut,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.requireAuth);
  const requireNoAuth = to.matched.some((record) => record.meta.requireNoAuth);
  const token =
    localStorage.getItem("instalkAdmin__token") ||
    sessionStorage.getItem("instalkAdmin__token");

  if (!store.getters.loaded) {
    await store.dispatch("getTypes");
    user = token ? await store.dispatch("getMe", token) : null;
  }
  let user = store.getters.user;
  if (requireAuth) {
    // 사용자만 접근 가능
    if (user && user.isAdmin) {
      // 사용자가 있는 경우
      next();
    } else {
      // 사용자가 없는 경우
      alert("권한이 없습니다.");
      next("/account/login");
    }
  } else if (requireNoAuth) {
    if (user) {
      next(from);
    } else {
      next();
    }
  } else {
    next();
  }
  Notification.requestPermission().then(async (permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const messaging = firebase.messaging();

      try {
        const pushToken = await messaging.getToken();
        await store.dispatch("setPushToken", pushToken);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Unable to get permission to notify.");
    }
  });
  store.commit("set", ["loaded", true]);
});

export default router;
