<template>
  <v-layout fill-height align-center justify-center> </v-layout>
</template>

<script>
export default {
  name: "SignOut",
  methods: {
    async SignOut() {
      console.log("signout");
      localStorage.removeItem("instalkAdmin__token");
      this.$store.commit("set", ["user", null]);
      this.axios.defaults.headers.common = {};
      this.$router.push("/");
    },
  },
  mounted() {
    this.SignOut();
  },
};
</script>

<style></style>
