<template>
  <v-navigation-drawer
    :mini-variant="miniVariant"
    permanent
    clipped
    app
    v-model="drawer"
  >
    <v-list dense nav class="py-0">
      <v-list-item two-line>
        <v-list-item-content class="mt60">
          <div v-if="!miniVariant" class="d-flex justify-space-between">
            <h4 class="text-truncate">Admin menu</h4>
            <v-icon @click="miniVariant = true">mdi-chevron-left</v-icon>
          </div>
          <v-icon v-else @click="miniVariant = false">mdi-menu</v-icon>
        </v-list-item-content>
      </v-list-item>

      <template v-for="item in items">
        <v-list-item v-if="!item.children" :key="item.title" :to="item.path">
          <v-list-item-icon>
            <v-icon v-text="item.action" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-else
          :prepend-icon="item.action"
          ref="listItem"
          :key="item.title"
          :to="item.path"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="subItem in item.children"
            :key="subItem.title"
            :to="subItem.path"
          >
            <v-list-item-icon>
              <v-icon v-text="subItem.action"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      item: 1,
      miniVariant: true,
      items: [
        {
          action: "mdi-home",
          title: "홈",
          path: "/",
        },
        {
          action: "mdi-account",
          title: "사용자 관리",
          path: "/user",
        },
        {
          action: "mdi-tag",
          title: "카테고리 관리",
          path: "/type",
        },
        {
          action: "mdi-bulletin-board",
          title: "공지사항 관리",
          path: "/notice",
        },
        {
          action: "mdi-gift",
          title: "이벤트 관리",
          path: "/events",
        },
        {
          action: "mdi-help-circle-outline",
          title: "문의 관리",
          path: "/inquiry",
        },
        {
          action: "mdi-text-box-outline",
          title: "약관 관리",
          path: "/terms",
        },
        {
          action: "mdi-alpha-p-circle-outline",
          title: "캐시 / 마일리지 관리",
          path: "/points",
        },
        {
          action: "mdi-currency-usd-circle-outline",
          title: "결제 금액 관리",
          path: "/prices",
        },
      ],
    };
  },
};
</script>
