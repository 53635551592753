import Vue from "vue";
import Vuex from "vuex";
import types from "../../../frontend/src/lib/types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loaded: false,
    token: null,
    user: null,
    types,
  },
  mutations: {
    set(s, [k, v]) {
      s[k] = v;
    },
  },
  getters: {
    token(s) {
      return s.token;
    },
    user(s) {
      return s.user;
    },
    loaded(s) {
      return s.loaded;
    },
    types(s) {
      return s.types;
    },
  },
  actions: {
    async getMe({ commit }, payload) {
      const token = payload || localStorage.getItem("instalkAdmin__token");
      if (token) {
        try {
          Vue.axios.defaults.headers.common["Authorization"] = token;
          const { data } = await axios.get("/account/me", {
            params: { token },
          });
          const user = data.user;
          console.log("user", user);
          commit("set", ["user", user]);
          commit("set", ["loaded", true]);
          return user;
        } catch (error) {
          commit("set", ["loaded", true]);
          return null;
        }
      } else {
        commit("set", ["loaded", true]);
        return null;
      }
    },
    async getItems(context, params) {
      try {
        let { data } = await axios.get("/items", { params });
        const { docs, totalDocs, page, totalPages, nextPage } = data.data;
        const result = {
          items: docs,
          totalDocs,
          page,
          totalPages,
          nextPage,
        };
        console.log(result);
        return {
          items: docs,
          totalDocs,
          page,
          totalPages,
          nextPage,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async setPushToken({ commit }, pushToken) {
      try {
        const { data } = await axios.patch("/account/push", { pushToken });
        console.log(data);
      } catch (error) {
        return null;
      }
    },
  },
});
