<template>
  <v-app-bar app clipped-left>
    <div class="d-flex align-center">
      Instalk Admin
    </div>

    <v-spacer></v-spacer>
    <v-btn small fab to="/account/sign-out">
      <v-icon small>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style></style>
